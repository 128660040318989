import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components'

import Chevron from "../images/icon-chevron-white.svg"

const Pagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;

  a {
    font-size: 19px;
    font-weight: var(--font-bold);
    color: var(--white);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      color: var(--pink);
    }
  }

  .right-chevron {
    transform: rotate(180deg);
  }
`

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  
  return (
    <Pagination>
      <div className="prev-posts">
        {previousPagePath && (
          <Link to={previousPagePath}>
            <img src={Chevron} alt="chevron" /> Newer Posts
          </Link>
        )}
      </div>

      <div className="older-posts">
        {nextPagePath && (
          <Link to={nextPagePath}>
            Older Posts <img className="right-chevron" src={Chevron} alt="chevron" />
          </Link>
        )}
      </div>
    </Pagination>
  );
};

export default Pager;